<template>
  <div>
    <el-row :gutter="20" class="mb20">
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-1">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.adminCount}}</div>
              <div>用户总数</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-1">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.userCount}}</div>
              <div>管理员总数</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-2">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.projectCount}}</div>
              <div>项目总数</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-2">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.houseTypeCount}}</div>
              <div>户型总数</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-2">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.adviserCount}}</div>
              <div>顾问总数</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-3">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.reservationCount}}</div>
              <div>预约数量</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-4">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.activeCount}}</div>
              <div>资讯数量</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" style="padding-bottom: 20px;">
        <el-card shadow="hover" :body-style="{padding: '0px'}">
          <div class="grid-content grid-con-4">
            <div class="grid-cont-right">
              <div class="grid-num">{{stat.carouselCount}}</div>
              <div>轮播数量</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        stat: {
          adminCount: 0,
          userCount: 0,
          projectCount: 0,
          houseTypeCount: 0,
          adviserCount: 0,
          reservationCount: 0,
          activeCount: 0,
          carouselCount: 0
        }
      }
    },
    created () {
      this.getStat()
    },
    methods: {
      async getStat() {
        const {data: res} = await this.$axios.get('/stat')
        if (res.code === 0) {
          this.stat = {
            adminCount: res.data.adminCount,
            userCount: res.data.userCount,
            projectCount: res.data.projectCount,
            houseTypeCount: res.data.houseTypeCount,
            adviserCount: res.data.adviserCount,
            reservationCount: res.data.reservationCount,
            activeCount: res.data.activeCount,
            carouselCount: res.data.carouselCount
          }
        } else {
          this.$notify({title: res.msg, type: 'error'})
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .grid-content
    display flex
    align-items center
    height 100px
  .grid-cont-right
    flex 1
    text-align center
    font-size 14px
    color #999
  .grid-num
    font-size 30px
    font-weight bold
  .cav-info
    border-radius 6px
    overflow hidden
</style>
