<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        注册时间：<el-date-picker
            v-model="searchForm.createDateSegment"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="handle-input mr10 mb10">
        </el-date-picker>
        <el-input v-model="searchForm.nickname" placeholder="请输入昵称" class="handle-input mr10 mb10"></el-input>
        <el-input v-model="searchForm.name" placeholder="请输入姓名" class="handle-input mr10 mb10"></el-input>
        <el-input v-model="searchForm.phone" placeholder="请输入电话" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
		<el-button type="default" @click="resetFilters">清空筛选条件</el-button>
        <el-button type="primary" @click="addUserVisible = true,initUserForm()">添加</el-button>
        <el-button type="primary" @click="exportUserList">导出</el-button>
      </div>
      <el-table :data="userList" border stripe height="680" style="width: 100%;min-height: 550px;" ref="userTable">
		  <el-table-column label="序号" prop="index" min-width="100" align="center"></el-table-column>
    <!--    <el-table-column label="编号" prop="userId" min-width="200" align="center"></el-table-column> -->
        <el-table-column label="头像" min-width="150" align="center">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatar" alt="" class="el-image" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname" min-width="100" align="center"></el-table-column>
		<el-table-column label="注册时间" prop="createDate" min-width="200" align="center">
			  <template slot-scope="scope">
			    {{ formatDateTime(scope.row.createDate) }}
			  </template>
		</el-table-column>
       <!-- <el-table-column label="微信openid" prop="openid" min-width="200" align="center"></el-table-column> -->
        <el-table-column label="状态" prop="state" min-width="200" align="center">
         <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template> 
        </el-table-column>
        <el-table-column label="姓名" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="性别" prop="gender" min-width="200" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.gender == 1">男</span>
            <span v-if="scope.row.gender == 2">女</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone" min-width="200" align="center"></el-table-column>
        <el-table-column label="地址" prop="address" min-width="200" align="center"></el-table-column>
        
    <!--    <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column> -->
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditUser(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteUserConfirm(scope.row.userId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新用户-->
    <el-dialog title="添加用户" :visible.sync="addUserVisible" width="600px">
      <el-form :model="userForm" status-icon :rules="userRules" ref="addUserForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="头像" prop="avatar">
          <el-avatar :src="userForm.avatar" alt="" class="el-image-mid" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="userForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="微信openid" prop="openid">
          <el-input v-model="userForm.openid"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="userForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="userForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="userForm.gender" placeholder="请选择性别">
            <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="userForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="userForm.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUserForm('addUserForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="用户编辑" :visible.sync="editUserVisible" width="600px">
      <el-form :model="userForm" status-icon :rules="userRules" ref="editUserForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="头像" prop="avatar">
          <el-avatar :src="userForm.avatar" alt="" class="el-image-mid" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="userForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="微信openid" prop="openid">
          <el-input v-model="userForm.openid" disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="userForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="userForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="userForm.gender" placeholder="请选择性别">
            <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="userForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="userForm.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUserForm('editUserForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delUserVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
export default {
  name: 'user',
  data () {
    return {
      config: '',
      userForm: {
        userId: '',
        nickname: '',
        avatar: '',
        openid: '',
        name: '',
        phone: '',
        address: '',
        state: 1
      },
      userList: [], // 记录用户信息，用于显示
      searchForm: {
        state: 1,
        createDateSegment: '',
        nickname: '',
        name: '',
        phone: ''
      }, // 搜索
      addUserVisible: false, // 显示添加框
      editUserVisible: false, // 显示编辑框
      delUserVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 500,
        total: 0
      },
      genderList: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 2,
          name: '女'
        }
      ],
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      userRules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '昵称长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        avatar: [
          { required: true, message: '请输入头像链接', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '头像链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        openid: [
          { required: true, message: '请输入微信openid', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '微信openid长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getUserList(this.searchForm)
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化用户表单数据
    initUserForm() {
      this.userForm = {
        userId: '',
        nickname: '',
        avatar: '',
        openid: '',
        name: '',
        gender: '',
        phone: '',
        address: '',
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getUserList(this.searchForm)
    },
	//处理时间
	 formatDateTime(dateTime) {
	    return dateTime.replace('T', ' ');
	  },
	  //导出表格
	  exportUserList() {
	        const headers = ['序号', '昵称', '注册时间', '状态', '姓名', '性别', '电话', '地址','最后更新时间'];
	        const rows = this.userList.map(user => [
			  user.index, // 计算序号
	          user.nickname,
	          this.formatDateTime(user.createDate),
	          user.state === 1 ? '生效中' : '未生效',
	          user.name,
	          user.gender === 1 ? '男' : user.gender === 2 ? '女' : '',
	          user.phone,
	          user.address,
			  this.formatDateTime(user.updateDate)
	        ]);
	  
	        let csvContent = headers.join(',') + '\n' + rows.map(e => e.join(',')).join('\n');
	        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	        saveAs(blob, 'user_list.csv');
	    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getUserList(this.searchForm)
    },
	// 清空条件
	resetFilters() {
	      this.searchForm = {
	       state: '',
	       createDateSegment: '',
	       nickname: '',
	       name: '',
	       phone: ''
	      }
		  },
    // 用户片上传成功事件
    uploadSuccess(res) {
      this.userForm.avatar = res.data
    },
    // 获取用户列表
    async getUserList (params) {
      Object.assign(params, params, this.page)
      const paramsTemp = {
        state: params.state,
        nickname: params.nickname,
        name: params.name,
        phone: params.phone,
        page: params.page,
        size: params.size,
        startDate: params.createDateSegment[0],
        endDate: params.createDateSegment[1]
      }
      const { data: res } = await this.$axios.get('/user/page', { params: paramsTemp})
      const arrData = res.data.records.map((item, index) => ({
      index: index + 1 + (this.page.page - 1) * this.page.size, // 计算序号
      userId: item.userId,
      nickname: item.nickname,
      avatar: item.avatar,
      openid: item.openid,
      name: item.name,
      gender: item.gender,
      phone: item.phone,
      address: item.address,
      state: item.state,
      createDate: item.createDate,
      updateDate: item.updateDate
    }))
      this.page.total = res.data.total
      this.userList = arrData
    },
    // // 导出用户列表
    // async exportUserList () {
    //   const params = this.searchForm
    //   const paramsTemp = {
    //     state: params.state,
    //     nickname: params.nickname,
    //     name: params.name,
    //     phone: params.phone,
    //     startDate: params.createDateSegment[0],
    //     endDate: params.createDateSegment[1]
    //   }
    //   await this.exportExcel('/user/export', paramsTemp)
    // },
    // 提交表单
    submitUserForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addUserForm') {
            this.addUser()
          } else if (formName === 'editUserForm') {
            this.editUser()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加用户
    async addUser () {
      const { data: res } = await this.$axios.post('/user', this.userForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addUserVisible = false
      this.getUserList(this.searchForm)
    },
    // 加载编辑用户
    loadEditUser (row) {
      this.userForm = {
        userId: row.userId,
        nickname: row.nickname,
        avatar: row.avatar,
        openid: row.openid,
        name: row.name,
        gender: row.gender,
        phone: row.phone,
        address: row.address,
        state: row.state
      }
      this.editUserVisible = true
    },
    // 编辑用户
    async editUser () {
      const { data: res } = await this.$axios.put('/user', this.userForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editUserVisible = false
      this.getUserList(this.searchForm)
    },
    deleteUserConfirm (userId) {
      this.userForm.userId = userId
      this.delUserVisible = true
    },
    // 删除用户
    async deleteUser () {
      const { data: res } = await this.$axios.delete('/user', { params: { userId: this.userForm.userId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delUserVisible = false
      this.getUserList(this.searchForm)
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
.el-image-mid
  width 200px
  height 200px
.el-table td
  padding 6px 0
</style>