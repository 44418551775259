<template>
  <div class="login-wrap">
    <div class="ms-title">LookHouse</div>
    <div class="ms-login">
      <el-form ref="loginForm" class="demo-ruleForm" :model="loginForm" :rules="loginRules">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password"
                    v-model="loginForm.password"
                    placeholder="请输入密码"
                    @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="login">登 录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>

  export default {
    data: function () {
      return {
        loginForm: {
          username: 'admin',
          password: '8888'
        },
        loginRules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '用户名长度在 2 到 20 个字符',
              trigger: 'blur'
            }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
              min: 4,
              max: 20,
              message: '密码长度在 4 到 20 个字符',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    methods: {
      // 登录
      login () {
        this.$refs['loginForm'].validate(async (valid) => {
          if(valid) {
            const { data: res } = await this.$axios.post('/admin/login', this.loginForm)
            if(res.code === 0) {
              // 解析数据
              const adminInfo = {
                adminId: res.data.adminId,
                username: res.data.username,
                avatar: res.data.avatar,
                phone: res.data.phone,
                dept: res.data.dept,
                post: res.data.post,
                type: res.data.type
              }
              const token = res.data.token

              // 共享数据
              this.$store.commit("SET_TOKEN", token);
              this.$store.commit("SET_ADMININFO", adminInfo);

              // 提示登录成功
              this.$notify({title: res.msg, type: 'success'})

              // 跳转首页
              setTimeout(() => {
                this.$router.push('/')
              }, 500)
            } else {
              this.$notify({title: res.msg, type: 'error'})
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .login-wrap
    position relative
    background url('../assets/img/background.jpg')
    background-attachment fixed
    background-position center
    background-size cover
    width 100%
    height 100%
  .ms-title
    position absolute
    top 50%
    width 100%
    margin-top -230px
    text-align center
    font-size 30px
    font-weight 600
    color #fff
  .ms-login
    position absolute
    left 50%
    top 50%
    width 300px
    height 160px
    margin -150px 0 0 -190px
    padding 40px
    border-radius 5px
    background #fff
  .login-btn
    text-align center
    button
      width 100%
      height 36px
</style>
