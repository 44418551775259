import axios from "axios";
import Element from 'element-ui'
import router from '../../router'
import store from '../../store'

// 前置拦截
axios.interceptors.request.use(config => {
        config.headers['Authorization'] = sessionStorage.getItem("token")
        return config
    },
        error => Promise.reject(error)
)

// 后置拦截
axios.interceptors.response.use(response => {
        if (response.request.responseURL.indexOf('/export') > -1) {
            return response
        }
        let res = response.data;
        switch (res.code) {
            case 0: {
                return response;
            }
            case 500101: {
                store.commit("REMOVE_INFO");
                router.push("login");
                break;
            }
            default: {
                Element.Message.error(res.msg);
            }
        }
        return Promise.reject(res.msg);
    }, error => {
        if (!error.response) {
            return Promise.reject(error);
        }
        if (error.response.data) {
            if (error.response.data.code == 500101) {
                store.commit("REMOVE_INFO");
                router.push("login");
            } else {
                error.message = error.response.data.msg;
            }
        }
        Element.Message.error(error.message);
        return Promise.reject(error);
    }
)
