<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="searchForm.projectId" placeholder="请选择所属项目" class="handle-input mr10 mb10">
          <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.name"
              :value="item.projectId">
          </el-option>
        </el-select>
        <el-input v-model="searchForm.name" placeholder="请输入名称" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
		<el-button type="default" @click="resetFilters">清空筛选条件</el-button>
        <el-button type="primary" @click="addHouseTypeVisible = true,initHouseTypeForm()">添加</el-button>
      </div>
      <el-table :data="houseTypeList" border style="width: 100%;min-height: 550px;" ref="houseTypeTable">
        <el-table-column label="编号" prop="houseTypeId" min-width="200" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属项目" prop="projectName" min-width="200" align="center"></el-table-column>
        <el-table-column label="户型面积" prop="description" min-width="200" align="center"></el-table-column>
        <el-table-column label="价格" prop="price" min-width="200" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditHouseType(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteHouseTypeConfirm(scope.row.houseTypeId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新户型-->
    <el-dialog title="添加户型" :visible.sync="addHouseTypeVisible" width="1000px">
      <el-form :model="houseTypeForm" status-icon :rules="houseTypeRules" ref="addHouseTypeForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="户型主图" prop="houseTypeImage">
          <el-image :src="houseTypeForm.houseTypeImage" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadHouseTypeImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w710*h314;格式:png/jpg;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="houseTypeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="houseTypeForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="户型状态描述" prop="houseTypeState">
          <el-input v-model="houseTypeForm.houseTypeState"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="label">
          <el-input v-model="houseTypeForm.label"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="houseTypeForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="houseTypeForm.price"></el-input>
        </el-form-item>
		<el-form-item label="面积" prop="description">
		  <el-input v-model="houseTypeForm.description"></el-input>
		</el-form-item>
        <el-form-item label="所属项目" prop="projectId">
          <el-select v-model="houseTypeForm.projectId" placeholder="请选择所属项目">
            <el-option
                v-for="item in projectList"
                :key="item.projectId"
                :label="item.name"
                :value="item.projectId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="houseTypeForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <div class="el-image-list" v-for="item in houseTypeForm.image.split(',')">
            <el-image :src="item" alt="" class="el-image-mid">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button type="text" size="mini" @click="deleteImage(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:png/jpg;大小<=500k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <div class="el-image-list" v-for="item in houseTypeForm.video.split(',')">
            <video controls="controls" class="el-image-mid" :src="item"></video>
            <el-button type="text" size="mini" @click="deleteVideo(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeVideoUpload"
              :on-success="uploadVideoSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button>
			<div style="color: red;">注：(尺寸:w750*h460;格式:mp4;大小<=20M)</div>
          </el-upload>
        </el-form-item>
       
        <el-form-item label="详情" prop="detail">
          <editor v-model="houseTypeForm.detail"></editor>
        </el-form-item>
      </el-form>
	  <div style="color: red;">详情上传注：(图片尺寸:w750*不限高;格式:png/jpg;建议大小<=2M；视频尺寸:w750*不限高;格式:mp4;建议大小<=20M)</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addHouseTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHouseTypeForm('addHouseTypeForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="户型编辑" :visible.sync="editHouseTypeVisible" width="1000px">
      <el-form :model="houseTypeForm" status-icon :rules="houseTypeRules" ref="editHouseTypeForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="户型主图" prop="houseTypeImage">
          <el-image :src="houseTypeForm.houseTypeImage" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadHouseTypeImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w710*h314;格式:png/jpg;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="houseTypeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="houseTypeForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="户型状态描述" prop="houseTypeState">
          <el-input v-model="houseTypeForm.houseTypeState"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="label">
          <el-input v-model="houseTypeForm.label"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="houseTypeForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="houseTypeForm.price"></el-input>
        </el-form-item>
		<el-form-item label="面积" prop="description">
		  <el-input v-model="houseTypeForm.description"></el-input>
		</el-form-item>
        <el-form-item label="所属项目" prop="projectId">
          <el-select v-model="houseTypeForm.projectId" placeholder="请选择所属项目">
            <el-option
                v-for="item in projectList"
                :key="item.projectId"
                :label="item.name"
                :value="item.projectId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="houseTypeForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <div class="el-image-list" v-for="item in houseTypeForm.image.split(',')">
            <el-image :src="item" alt="" class="el-image-mid">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button type="text" size="mini" @click="deleteImage(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:png/jpg;大小<=500k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <div class="el-image-list" v-for="item in houseTypeForm.video.split(',')">
            <video controls="controls" class="el-image-mid" :src="item"></video>
            <el-button type="text" size="mini" @click="deleteVideo(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeVideoUpload"
              :on-success="uploadVideoSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:mp4;大小<=20M)</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="详情" prop="detail">
          <editor v-model="houseTypeForm.detail"></editor>
        </el-form-item>
      </el-form>
	  <div style="color: red;">详情上传注：(图片尺寸:w750*不限高;格式:png/jpg;建议大小<=2M；视频尺寸:w750*不限高;格式:mp4;建议大小<=20M)</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editHouseTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHouseTypeForm('editHouseTypeForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delHouseTypeVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delHouseTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteHouseType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Editor from "../components/Editor";

export default {
  name: 'houseType',
  components: {
    'editor': Editor
  },
  data () {
    return {
      config: '',
      houseTypeForm: {
        houseTypeId: '',
        projectId: '',
        name: '',
        description: '',
        detail: '',
        houseTypeImage: '',
        houseTypeState: '',
        label: '',
        contact: '',
        image: '',
        video: '',
        price: '',
        sort: 100,
        state: 1
      },
      houseTypeList: [], // 记录户型信息，用于显示
      searchForm: {
        state: 1,
        projectId: '',
        name: ''
      }, // 搜索
      addHouseTypeVisible: false, // 显示添加框
      editHouseTypeVisible: false, // 显示编辑框
      delHouseTypeVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      projectList: [], // 项目列表
      houseTypeRules: {
        projectId: [
          { required: true, message: '请选择所属项目', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '名称长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        houseTypeImage: [
          { required: true, message: '请选择户型主图', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '户型主图链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        contact: [
          { required: true, message: '请选择联系方式', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '联系方式链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        sort: [
          { required: true, message: '请输排序', trigger: 'blur' },
          { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getHouseTypeList(this.searchForm)
    this.getProjectList()
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化户型表单数据
    initHouseTypeForm() {
      this.houseTypeForm = {
        houseTypeId: '',
        name: '',
        description: '',
        detail: '',
        houseTypeImage: '',
        houseTypeState: '',
        label: '',
        contact: '',
        image: '',
        video: '',
        price: '',
        sort: 100,
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getHouseTypeList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getHouseTypeList(this.searchForm)
    },
	 // 清空条件 
	resetFilters() {
	      this.searchForm = {
	        state: '',
	        projectId: '',
	        name: ''
	      }
		  },
    // 户型主图上传成功事件
    uploadHouseTypeImageSuccess(res) {
      this.houseTypeForm.houseTypeImage = res.data
    },
    // 户型图片上传成功事件
    uploadImageSuccess(res) {
      if (this.houseTypeForm.image == '') {
        this.houseTypeForm.image = res.data
      } else {
        this.houseTypeForm.image += ',' + res.data
      }
    },
    // 户型视频上传成功事件
    uploadVideoSuccess(res) {
      if (this.houseTypeForm.video == '') {
        this.houseTypeForm.video = res.data
      } else {
        this.houseTypeForm.video += ',' + res.data
      }
    },
    // 删除图片
    async deleteImage (item) {
      const { data: res } = await this.$axios.delete('/file/delete', { params: { url: item } })
      if (res.code === 0) {
        this.houseTypeForm.image = this.houseTypeForm.image.replace(',' + item, '')
        this.houseTypeForm.image = this.houseTypeForm.image.replace(item, '')
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
    },
    // 删除视频
    async deleteVideo (item) {
      const { data: res } = await this.$axios.delete('/file/delete', { params: { url: item } })
      if (res.code === 0) {
        this.houseTypeForm.video = this.houseTypeForm.video.replace(',' + item, '')
        this.houseTypeForm.video = this.houseTypeForm.video.replace(item, '')
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
    },
    // 获取项目列表
    async getProjectList() {
      const {data: res} = await this.$axios.get(`/project/list`)
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.projectId = item.projectId
        arr.name = item.name
        arrData.push(arr)
      })
      this.projectList = arrData
    },
    // 获取户型列表
    async getHouseTypeList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/houseType/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.houseTypeId = item.houseTypeId
        arr.projectId = item.projectId
        arr.projectName = item.projectName
        arr.name = item.name
        arr.description = item.description
        arr.detail = item.detail
        arr.houseTypeImage = item.houseTypeImage
        arr.houseTypeState = item.houseTypeState
        arr.label = item.label
        arr.contact = item.contact
        arr.image = item.image
        arr.video = item.video
        arr.price = item.price
        arr.sort = item.sort
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.houseTypeList = arrData
    },
    // 提交表单
    submitHouseTypeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addHouseTypeForm') {
            this.addHouseType()
          } else if (formName === 'editHouseTypeForm') {
            this.editHouseType()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加户型
    async addHouseType () {
      const { data: res } = await this.$axios.post('/houseType', this.houseTypeForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addHouseTypeVisible = false
      this.getHouseTypeList(this.searchForm)
    },
    // 加载编辑户型
    loadEditHouseType (row) {
      this.houseTypeForm = {
        houseTypeId: row.houseTypeId,
        projectId: row.projectId,
        name: row.name,
        description: row.description,
        detail: row.detail,
        houseTypeImage: row.houseTypeImage,
        houseTypeState: row.houseTypeState,
        label: row.label,
        contact: row.contact,
        image: row.image,
        video: row.video,
        price: row.price,
        sort: row.sort,
        state: row.state
      }
      this.editHouseTypeVisible = true
    },
    // 编辑户型
    async editHouseType () {
      const { data: res } = await this.$axios.put('/houseType', this.houseTypeForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editHouseTypeVisible = false
      this.getHouseTypeList(this.searchForm)
    },
    deleteHouseTypeConfirm (houseTypeId) {
      this.houseTypeForm.houseTypeId = houseTypeId
      this.delHouseTypeVisible = true
    },
    // 删除户型
    async deleteHouseType () {
      const { data: res } = await this.$axios.delete(`/houseType`, { params: { houseTypeId: this.houseTypeForm.houseTypeId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delHouseTypeVisible = false
      this.getHouseTypeList(this.searchForm)
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('file', file)
      this.$axios({
        url:`/file/upload`,
        method: 'POST',
        data: formData
      }).then(res => {
        Editor.insertEmbed(cursorLocation, 'image', res.data.data)
        resetUploader()
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
</style>
