<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">LookHouse</div>
    <div class="root"></div>
    <div class="header-right">
      <div class="header-user-con">
        <el-avatar size="small" :src="avatar"></el-avatar>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{username}}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal">个人管理</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>

  import _ctrEvent from '../assets/js/ctr-event'

  export default {
    data () {
      return {
        collapse: true, // 折叠控制按钮数据
        fullscreen: false,
        username: '',
        avatar: ''
      }
    },
    created() {
      this.username = this.$store.state.adminInfo.username
      this.avatar = this.$store.state.adminInfo.avatar
    },
    mounted () {
      if (document.body.clientWidth < 1500) {
        this.collapseChage()
      }
    },
    methods: {
      // 用户名下拉菜单选择事件
      handleCommand (command) {
        if (command === 'personal') {
          this.$router.push('/personal')
        } else if (command === 'logout') {
          this.$store.commit("REMOVE_INFO");
          this.$router.push('/login')
        }
      },
      // 侧边栏折叠
      collapseChage () {
        this.collapse = !this.collapse
        // 将侧边栏折叠数据传送到父组件，触发父组件collapse事件
        _ctrEvent.$emit('collapse', this.collapse)
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .header
    position absolute
    z-index 100
    box-sizing border-box
    width 100%
    height 70px
    font-size 22px
    color #b0b3b2
    background #1a1b1c
    box-shadow 0px 0px 8px 2px rgba(0, 0, 0, 0.3)
    .logo
      float left
      width 250px
      font-family 26px
      line-height 70px
  .collapse-btn
    float left
    padding 0 21px
    cursor pointer
    line-height 70px
  .header-right
    float right
    padding-right 50px
  .header-user-con
    display flex
    height 70px
    align-items center
  .user-name
    margin-left 10px
  .user-avator
    margin-left 20px
    img
      display block
      width 40px
      height 40px
      border-radius 50%
  .el-dropdown-link
    color #b0b3b2
    cursor pointer
  .el-dropdown-menu__item
    text-align center
</style>
