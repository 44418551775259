// 用于存放公共处理函数

import Vue from 'vue'

// 图片上传之前事件
Vue.prototype.beforeImageUpload = function (file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isJPG && !isPNG) {
        this.$notify({title: '上传图片只能是 JPG 或者 PNG 格式!', type: 'error'})
    }
    if (!isLt5M) {
        this.$notify({title: '上传图片大小不能超过 5MB!', type: 'error'})
    }
    return (isJPG || isPNG) && isLt5M;
}
// 视频上传之前事件
Vue.prototype.beforeVideoUpload = function (file) {
    const isMP4 = file.type === 'video/mp4';
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isMP4) {
        this.$notify({title: '上传视频只能是 MP4 格式!', type: 'error'})
    }
    if (!isLt20M) {
        this.$notify({title: '上传视频大小不能超过 20MB!', type: 'error'})
    }
    return isMP4 && isLt20M;
}
// 文件上传之前事件
Vue.prototype.beforeFileUpload = function (file) {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
        this.$notify({title: '上传文件大小不能超过 10MB!', type: 'error'})
    }
    return isLt10M;
}

// 校验整数
Vue.prototype.checkInt = (rule, value, callback) => {
    if ((Number(value))&&(value)%1 === 0) {
        callback();
    }else {
        return callback(new Error('请输入整数！'));
    }
};

// excel导出
Vue.prototype.exportExcel = async function (url, params) {
    const response = await this.$axios.get(url, {params: params, responseType: 'blob'});
    const fileName = response.headers['content-disposition'].split('=')[1]
    const href = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', decodeURIComponent(fileName));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
