<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input v-model="searchForm.name" placeholder="请输入名称" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
        <el-button type="primary" @click="addActiveVisible = true,initActiveForm()">添加</el-button>
      </div>
      <el-table :data="activeList" border style="width: 100%;min-height: 550px;" ref="activeTable">
        <el-table-column label="编号" prop="activeId" min-width="200" align="center"></el-table-column>
        <el-table-column label="图片" min-width="150" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.image" alt="" class="el-image" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="资讯时间 " prop="activeDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="链接" prop="url" min-width="200" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditActive(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteActiveConfirm(scope.row.activeId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新资讯-->
    <el-dialog title="添加资讯" :visible.sync="addActiveVisible" width="1000px">
      <el-form :model="activeForm" status-icon :rules="activeRules" ref="addActiveForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="图片" prop="image">
          <el-image :src="activeForm.image" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w710*h314;格式:jpg/png;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="activeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="activeForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="activeForm.url"></el-input>
        </el-form-item>
        <el-form-item label="资讯时间" prop="activeDate">
          <el-input v-model="activeForm.activeDate"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="activeForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <editor v-model="activeForm.detail"></editor>
        </el-form-item>
		<div style="color: red;">详情上传注：(图片尺寸:w750*不限高;格式:png/jpg;建议大小<=2M；视频尺寸:w750*不限高;格式:mp4;建议大小<=20M)</div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addActiveVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitActiveForm('addActiveForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="资讯编辑" :visible.sync="editActiveVisible" width="1000px">
      <el-form :model="activeForm" status-icon :rules="activeRules" ref="editActiveForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="图片" prop="image">
          <el-image :src="activeForm.image" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="activeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="activeForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="activeForm.url"></el-input>
        </el-form-item>
        <el-form-item label="资讯时间" prop="activeDate">
          <el-input v-model="activeForm.activeDate"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="activeForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <editor v-model="activeForm.detail"></editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editActiveVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitActiveForm('editActiveForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delActiveVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delActiveVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteActive">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Editor from "../components/Editor";

export default {
  name: 'active',
  components: {
    'editor': Editor
  },
  data () {
    return {
      config: '',
      activeForm: {
        activeId: '',
        name: '',
        image: '',
        url: '',
        activeDate: '',
        detail: '',
        sort: 100,
        state: 1
      },
      activeList: [], // 记录资讯信息，用于显示
      searchForm: {
        state: 1,
        name: ''
      }, // 搜索
      addActiveVisible: false, // 显示添加框
      editActiveVisible: false, // 显示编辑框
      delActiveVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      activeRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '名称长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        url: [
          { required: true, message: '请输入链接', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        sort: [
          { required: true, message: '请输排序', trigger: 'blur' },
          { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getActiveList(this.searchForm)
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化资讯表单数据
    initActiveForm() {
      this.activeForm = {
        activeId: '',
        name: '',
        image: '',
        url: '',
        activeDate: '',
        detail: '',
        sort: 100,
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getActiveList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getActiveList(this.searchForm)
    },
    // 资讯片上传成功事件
    uploadSuccess(res) {
      this.activeForm.image = res.data
    },
    // 获取资讯列表
    async getActiveList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/active/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.activeId = item.activeId
        arr.name = item.name
        arr.image = item.image
        arr.url = item.url
        arr.activeDate = item.activeDate
        arr.detail = item.detail
        arr.sort = item.sort
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.activeList = arrData
    },
    // 提交表单
    submitActiveForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addActiveForm') {
            this.addActive()
          } else if (formName === 'editActiveForm') {
            this.editActive()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加资讯
    async addActive () {
      const { data: res } = await this.$axios.post('/active', this.activeForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addActiveVisible = false
      this.getActiveList(this.searchForm)
    },
    // 加载编辑资讯
    loadEditActive (row) {
      this.activeForm = {
        activeId: row.activeId,
        name: row.name,
        image: row.image,
        url: row.url,
        activeDate: row.activeDate,
        detail: row.detail,
        sort: row.sort,
        state: row.state
      }
      this.editActiveVisible = true
    },
    // 编辑资讯
    async editActive () {
      const { data: res } = await this.$axios.put('/active', this.activeForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editActiveVisible = false
      this.getActiveList(this.searchForm)
    },
    deleteActiveConfirm (activeId) {
      this.activeForm.activeId = activeId
      this.delActiveVisible = true
    },
    // 删除资讯
    async deleteActive () {
      const { data: res } = await this.$axios.delete(`/active`, { params: { activeId: this.activeForm.activeId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delActiveVisible = false
      this.getActiveList(this.searchForm)
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('file', file)
      this.$axios({
        url:`/file/upload`,
        method: 'POST',
        data: formData
      }).then(res => {
        Editor.insertEmbed(cursorLocation, 'image', res.data.data)
        resetUploader()
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
</style>
