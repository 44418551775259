<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
        <el-button type="primary" @click="addCarouselVisible = true,initCarouselForm()">添加</el-button>
      </div>
      <el-table :data="carouselList" border style="width: 100%;min-height: 550px;" ref="carouselTable">
        <el-table-column label="编号" prop="carouselId" min-width="200" align="center"></el-table-column>
        <el-table-column label="媒体" min-width="150" align="center">
          <template slot-scope="scope">
            <video v-if="scope.row.type == 1" controls="controls" width="100px" height="100px" :src="scope.row.media"></video>
            <el-image v-if="scope.row.type == 2" :src="scope.row.media" alt="" class="el-image" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 1">视频</el-tag>
            <el-tag type="warning" v-if="scope.row.type == 2">图片</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="url" min-width="200" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditCarousel(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteCarouselConfirm(scope.row.carouselId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
                @current-change="changePage"
                background
                layout="total, prev, pager, next"
                :current-page="page.page"
                :page-size="page.size"
                :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新轮播-->
    <el-dialog title="添加轮播" :visible.sync="addCarouselVisible" width="600px">
      <el-form :model="carouselForm" status-icon :rules="carouselRules" ref="addCarouselForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="类型" prop="type">
          <el-select v-model="carouselForm.type" placeholder="请选择类型">
            <el-option
                v-for="item in carouselTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="媒体" prop="media">
          <video v-if="carouselForm.type == 1" controls="controls" width="100px" height="100px" :src="carouselForm.media"></video>
          <el-image v-if="carouselForm.type == 2" :src="carouselForm.media" alt="" class="el-image-big1" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="carouselForm.type == 1 ? beforeVideoUpload : beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w750*h1000;格式:jpg/png;大小<=300k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="carouselForm.url"></el-input>
		  <div style="color: red;">项目跳转链接：/pages/hoDetail/index?projectId=（项目编号)</div>
        </el-form-item>
		
        <el-form-item label="排序" prop="sort">
          <el-input v-model="carouselForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="carouselForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCarouselVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCarouselForm('addCarouselForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="轮播编辑" :visible.sync="editCarouselVisible" width="600px">
      <el-form :model="carouselForm" status-icon :rules="carouselRules" ref="editCarouselForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="类型" prop="type">
          <el-select v-model="carouselForm.type" placeholder="请选择类型">
            <el-option
                v-for="item in carouselTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="媒体" prop="media">
          <video v-if="carouselForm.type == 1" controls="controls" width="100px" height="100px" :src="carouselForm.media"></video>
          <el-image v-if="carouselForm.type == 2" :src="carouselForm.media" alt="" class="el-image-big1" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
                  class="upload-demo"
                  :action="$baseURL + '/file/upload'"
              :headers="config"
                  :before-upload="carouselForm.type == 1 ? beforeVideoUpload : beforeImageUpload"
                  :on-success="uploadSuccess"
                  :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="carouselForm.url"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="carouselForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="carouselForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCarouselVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCarouselForm('editCarouselForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delCarouselVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delCarouselVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteCarousel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    name: 'carousel',
    data () {
      return {
        config: '',
        carouselForm: {
          carouselId: '',
          media: '',
          type: 2,
          url: '',
          sort: 100,
          state: 1
        },
        carouselList: [], // 记录轮播信息，用于显示
        searchForm: {
          state: 1
        }, // 搜索
        addCarouselVisible: false, // 显示添加框
        editCarouselVisible: false, // 显示编辑框
        delCarouselVisible: false, // 显示删除框
        page: {
          page: 1,
          size: 10,
          total: 0
        },
        stateList: [
          {
            id: 1,
            name: '生效中'
          },
          {
            id: 0,
            name: '未生效'
          }
        ],
        carouselTypeList: [
          {
            id: 1,
            name: '视频'
          },
          {
            id: 2,
            name: '图片'
          }
        ],
        carouselRules: {
          type: [
            { required: true, message: '类型不能为空', trigger: ['blur', 'change'] }
          ],
          media: [
            { required: true, message: '请输入媒体链接', trigger: 'blur' },
            {
              min: 2,
              max: 255,
              message: '媒体链接长度在 2 到 255 个字符',
              trigger: 'blur'
            }
          ],
          url: [
            { required: true, message: '请输入链接', trigger: 'blur' },
            {
              min: 2,
              max: 255,
              message: '链接长度在 2 到 255 个字符',
              trigger: 'blur'
            }
          ],
          sort: [
            { required: true, message: '请输排序', trigger: 'blur' },
            { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '请选择状态', trigger: 'blur' },
          ]
        }
      }
    },
    created () {
      this.getCarouselList(this.searchForm)
      this.config = {
        'Authorization': this.$store.state.token
      }
    },
    methods: {
      // 初始化轮播表单数据
      initCarouselForm() {
        this.carouselForm = {
          carouselId: '',
          media: '',
          type: 2,
          url: '',
          sort: 100,
          state: 1
        }
      },
      // 切换页
      changePage (page) {
        this.page.page = page
        this.getCarouselList(this.searchForm)
      },
      // 搜索事件
      searchEvent() {
        this.page.page = 1
        this.getCarouselList(this.searchForm)
      },
      // 轮播片上传成功事件
      uploadSuccess(res) {
        this.carouselForm.media = res.data
      },
      // 获取轮播列表
      async getCarouselList (params) {
        Object.assign(params, params, this.page)
        const { data: res } = await this.$axios.get(`/carousel/page`, { params: params})
        const arrData = []
        res.data.records.forEach(item => {
          const arr = {}
          arr.carouselId = item.carouselId
          arr.media = item.media
          arr.type = item.type
          arr.url = item.url
          arr.sort = item.sort
          arr.state = item.state
          arr.createDate = item.createDate
          arr.updateDate = item.updateDate
          arrData.push(arr)
        })
        this.page.total = res.data.total
        this.carouselList = arrData
      },
      // 提交表单
      submitCarouselForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (formName === 'addCarouselForm') {
              this.addCarousel()
            } else if (formName === 'editCarouselForm') {
              this.editCarousel()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },
      // 添加轮播
      async addCarousel () {
        const { data: res } = await this.$axios.post('/carousel', this.carouselForm)
        if (res.code === 0) {
          this.$notify({title: res.msg, type: 'success'})
        } else {
          this.$notify({title: res.msg, type: 'error'})
        }
        this.addCarouselVisible = false
        this.getCarouselList(this.searchForm)
      },
      // 加载编辑轮播
      loadEditCarousel (row) {
        this.carouselForm = {
          carouselId: row.carouselId,
          media: row.media,
          type: row.type,
          url: row.url,
          sort: row.sort,
          state: row.state
        }
        this.editCarouselVisible = true
      },
      // 编辑轮播
      async editCarousel () {
        const { data: res } = await this.$axios.put('/carousel', this.carouselForm)
        if (res.code === 0) {
          this.$notify({title: res.msg, type: 'success'})
        } else {
          this.$notify({title: res.msg, type: 'error'})
        }
        this.editCarouselVisible = false
        this.getCarouselList(this.searchForm)
      },
      deleteCarouselConfirm (carouselId) {
        this.carouselForm.carouselId = carouselId
        this.delCarouselVisible = true
      },
      // 删除轮播
      async deleteCarousel () {
        const { data: res } = await this.$axios.delete(`/carousel`, { params: { carouselId: this.carouselForm.carouselId }})
        if (res.code === 0) {
          this.$notify({title: res.msg, type: 'success'})
        } else {
          this.$notify({title: res.msg, type: 'error'})
        }
        this.delCarouselVisible = false
        this.getCarouselList(this.searchForm)
      }
    }
  }

</script>

<style lang="stylus" scoped>
  .handle-box
    margin-bottom 20px
    font-size 12px
  .handle-input
    width 200px
    display inline-block
    margin-right 10px
  .pagination
    display flex
    justify-content center
</style>
