import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
import User from '../views/User.vue'
import Admin from '../views/Admin.vue'
import Project from '../views/Project.vue'
import HouseType from '../views/HouseType.vue'
import Adviser from '../views/Adviser.vue'
import Location from '../views/Location.vue'
import Reservation from '../views/Reservation.vue'
import Active from '../views/Active.vue'
import Carousel from '../views/Carousel.vue'
import Personal from '../views/Personal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      { path: '/index', component: Index },
      { path: '/user', component: User },
      { path: '/admin', component: Admin },
      { path: '/project', component: Project },
      { path: '/houseType', component: HouseType },
      { path: '/adviser', component: Adviser },
      { path: '/location', component: Location },
      { path: '/reservation', component: Reservation },
      { path: '/active', component: Active },
      { path: '/carousel', component: Carousel },
      { path: '/personal', component: Personal }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
