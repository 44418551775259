import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import axios from 'axios'

import "element-ui/lib/theme-chalk/index.css"
import './assets/css/main.css'

import './assets/js/axios.js'
import './assets/js/common.js'

Vue.use(Element)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.window = window;

const baseURL = 'https://htry.cxl88.com'
// const baseURL = 'http://127.0.0.1:8090'
Vue.prototype.$baseURL = baseURL

axios.defaults.baseURL = baseURL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
