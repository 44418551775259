import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("token"),
    adminInfo: JSON.parse(sessionStorage.getItem("adminInfo")),
    //所有打开的路由
    openTab: [
      {
        icon: 'el-icon-s-data',
        index: 'index',
        type: 0,
        title: '系统首页'
      }
    ],
    // 激活状态，侧边导航和标签页的激活状态，初始为首页
    activeIndex: "index"
  },
  mutations: { // set
    SET_TOKEN: (state, token) => {
      state.token = token;
      sessionStorage.setItem("token",token);
    },
    SET_ADMININFO: (state, adminInfo) => {
      state.adminInfo = adminInfo;
      sessionStorage.setItem("adminInfo",JSON.stringify(adminInfo));
    },
    REMOVE_INFO: state => {
      state.token = '';
      state.adminInfo = {};
      sessionStorage.setItem("token",'');
      sessionStorage.setItem("adminInfo",JSON.stringify(''));
    },
    // 单机导航栏时添加标签页
    addTab(state, data) {
      if (state.openTab.indexOf(data) == -1) {
        if (data.index != 'index') {
          state.openTab.push(data);
        }
        state.activeIndex=data.index;
      } else {
        state.activeIndex=data.index;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
