<template>
  <div ref="editor"></div>
</template>

<script>
import E from 'wangeditor';

export default {
  props: {
    value: {
      type: String
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    const editor = new E(this.$refs.editor)
    this.editor = editor
    editor.config.customUploadImg = this.upload // 自定义图片上传接口
    editor.config.customUploadVideo = this.upload
    editor.config.uploadImgMaxLength = 20 // 限制上传图片数量
    editor.config.onchange = this.updateParentValue
    editor.create()
    this.editor.txt.html(this.value)
  },
  methods: {
    // 更新内容到父组件
    updateParentValue() {
      this.$emit('input', this.editor.txt.html());
    },
    // 上传的自定义处理函数
    async upload(files, insertImgFn) {
      try {
        const formData = new FormData()
        formData.append('file', files[0])

        const response = await this.$axios.post('/file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.code === 0) {
          insertImgFn(response.data.data)
        } else {
          this.$message.error(response.data.msg)
        }
      } catch (error) {
        console.error('上传出错', error)
      }
    }
  },
  watch: {
      value(newVal) {
        if (this.editor && newVal !== this.editor.txt.html()) {
          this.editor.txt.html(newVal);
        }
      }
    }
}
</script>

