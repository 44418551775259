<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#242526"
      text-color="#B0B3B2"
      active-text-color="#ffffff"
      unique-opened
      router>
      <template v-for="item in items">
          <template>
            <el-menu-item v-if="item.type == 0 || item.type == type" :index="item.index" :key="item.index" @click="addTab(item)">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
  import _ctrEvent from '../assets/js/ctr-event'

  export default {
    data () {
      return {
        collapse: false, // 当collapse为true时侧边栏折叠
        type: '',
        items: [
          {
            icon: 'el-icon-s-data',
            index: 'index',
            type: 0,
            title: '系统首页'
          },
          {
            icon: 'el-icon-user-solid',
            index: 'user',
            type: 0,
            title: '用户管理'
          },
          {
            icon: 'el-icon-service',
            index: 'admin',
            type: 1,
            title: '管理员管理'
          },
          {
            icon: 'el-icon-wallet',
            index: 'project',
            type: 0,
            title: '项目管理'
          },
          {
            icon: 'el-icon-bank-card',
            index: 'houseType',
            type: 0,
            title: '户型管理'
          },
          {
            icon: 'el-icon-s-check',
            index: 'adviser',
            type: 0,
            title: '顾问管理'
          },
          {
            icon: 'el-icon-location-outline',
            index: 'location',
            type: 0,
            title: '区位管理'
          },
          {
            icon: 'el-icon-phone',
            index: 'reservation',
            type: 0,
            title: '预约管理'
          },
          {
            icon: 'el-icon-crop',
            index: 'active',
            type: 0,
            title: '资讯管理'
          },
          {
            icon: 'el-icon-film',
            index: 'carousel',
            type: 0,
            title: '轮播管理'
          },
          {
            icon: 'el-icon-user',
            index: 'personal',
            type: 0,
            title: '个人管理'
          }
        ]
      }
    },
    computed: {
      onRoutes () {
        return this.$route.path.replace('/', '')
      }
    },
    created () {
      // 监听collapse事件，将侧边栏折叠数据更新为子组件提交的msg
      _ctrEvent.$on('collapse', msg => {
        this.collapse = msg
      })
      this.type = this.$store.state.adminInfo.type
    },
    methods: {
      addTab (item) {
        this.$store.commit('addTab', item)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .sidebar
    display block
    position absolute
    background-color #334256
    left 0
    top 70px
    bottom 0
    overflow-y scroll
    &::-webkit-scrollbar
      width 0
    & > ul
      height 100%
  .sidebar-el-menu
    &:not(.el-menu--collapse)
      width 150px
</style>
