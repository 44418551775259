<template>
  <div class="table">
    <div class="container">
      <el-form :model="adminForm" status-icon :rules="adminRules" ref="adminForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="头像" prop="avatar">
          <el-avatar :src="adminForm.avatar" alt="" class="el-image-mid1" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="adminForm.username"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password">
          <el-input v-model="adminForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="adminForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="dept">
          <el-input v-model="adminForm.dept"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="post">
          <el-input v-model="adminForm.post"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateAdmin">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

  export default {
    data: function () {
      return {
        config: '',
        adminForm: {
          adminId: '',
          username: '',
          password: '',
          avatar: '',
          phone: '',
          dept: '',
          post: ''
        },
        adminRules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '用户名长度在 2 到 20 个字符',
              trigger: 'blur'
            }
          ],
          password: [
            {
              min: 4,
              max: 20,
              message: '密码长度在 4 到 20 个字符',
              trigger: 'blur'
            }
          ],
          avatar: [
            { required: true, message: '请选择头像', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '电话长度在 2 到 20 个字符',
              trigger: 'blur'
            }
          ],
          dept: [
            { required: true, message: '请输入部门', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '部门长度在 2 到 255 个字符',
              trigger: 'blur'
            }
          ],
          post: [
            { required: true, message: '请输入职位', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '职位长度在 2 到 255 个字符',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    methods: {
      // 头像上传成功事件
      uploadSuccess(res) {
        this.adminForm.avatar = res.data
      },
      // 修改管理员信息
      updateAdmin () {
        this.$refs['adminForm'].validate(async (valid) => {
          if(valid) {
            const { data: res } = await this.$axios.put('/admin', this.adminForm)
            if(res.code === 0) {
              this.$notify({title: res.msg, type: 'success'})
              const adminInfo = this.$store.state.adminInfo
              adminInfo.username = this.adminForm.username
              adminInfo.avatar = this.adminForm.avatar
              adminInfo.phone = this.adminForm.phone
              adminInfo.dept = this.adminForm.dept
              adminInfo.post = this.adminForm.post
              this.$store.commit("SET_ADMININFO", adminInfo);
            } else {
              this.$notify({title: res.msg, type: 'error'})
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    },
    created() {
      this.adminForm = {
        adminId: this.$store.state.adminInfo.adminId,
        username: this.$store.state.adminInfo.username,
        password: '',
        avatar: this.$store.state.adminInfo.avatar,
        phone: this.$store.state.adminInfo.phone,
        dept: this.$store.state.adminInfo.dept,
        post: this.$store.state.adminInfo.post
      }
      this.config = {
        'Authorization': this.$store.state.token
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .handle-box
    margin-bottom 20px
    font-size 12px
  .handle-input
    width 200px
    display inline-block
    margin-right 10px
  .pagination
    display flex
    justify-content center
</style>
