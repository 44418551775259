<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input v-model="searchForm.name" placeholder="请输入名称" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
		<el-button type="default" @click="resetFilters">清空筛选条件</el-button>
        <el-button type="primary" @click="addProjectVisible = true,initProjectForm()">添加</el-button>
      </div>
      <el-table :data="projectList" border style="width: 100%;min-height: 550px;" ref="projectTable">
        <el-table-column label="编号" prop="projectId" min-width="200" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="建筑面积" prop="area" min-width="200" align="center"></el-table-column>
        <el-table-column label="标签" prop="label" min-width="200" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="450" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditProject(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteProjectConfirm(scope.row.projectId)">删除</el-button>
            <el-button type="warning" size="mini" class="table-el-button" @click="showHouseTypeList(scope.row.projectId)">户型列表</el-button>
            <el-button type="success" size="mini" class="table-el-button" @click="showAdviserList(scope.row.projectId)">顾问列表</el-button>
            <el-button type="info" size="mini" class="table-el-button" @click="showLocationList(scope.row.projectId)">区位列表</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新项目-->
    <el-dialog title="添加项目" :visible.sync="addProjectVisible" width="1000px">
      <el-form :model="projectForm" status-icon :rules="projectRules" ref="addProjectForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="项目主图" prop="projectImage">
          <el-image :src="projectForm.projectImage" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadProjectImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w710*h314;格式:png/jpg;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="projectForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="projectForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目状态描述" prop="projectState">
          <el-input v-model="projectForm.projectState"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="projectForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="label">
          <el-input v-model="projectForm.label"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="projectForm.price"></el-input>
        </el-form-item>
        <el-form-item label="面积" prop="area">
          <el-input v-model="projectForm.area"></el-input>
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-input v-model="projectForm.location">
            <el-button slot="append" @click="window.open('https://lbs.qq.com/getPoint/')">获取经纬度</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="经度" prop="location">
          <el-input v-model="projectForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="location">
          <el-input v-model="projectForm.latitude"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="projectForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <div class="el-image-list" v-for="item in projectForm.image.split(',')">
            <el-image :src="item" alt="" class="el-image-mid">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button type="text" size="mini" @click="deleteImage(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:png/jpg;大小<=500k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <div class="el-image-list" v-for="item in projectForm.video.split(',')">
            <video controls="controls" class="el-image-mid" :src="item"></video>
            <el-button type="text" size="mini" @click="deleteVideo(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeVideoUpload"
              :on-success="uploadVideoSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:mp4;大小<=20M)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" :rows="3" v-model="projectForm.description"></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <editor v-model="projectForm.detail"></editor>
        </el-form-item>
      </el-form>
	  <div style="color: red;">详情上传注：(图片尺寸:w750*不限高;格式:png/jpg;建议大小<=2M；视频尺寸:w750*不限高;格式:mp4;建议大小<=20M)</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitProjectForm('addProjectForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="项目编辑" :visible.sync="editProjectVisible" width="1000px">
      <el-form :model="projectForm" status-icon :rules="projectRules" ref="editProjectForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="项目主图" prop="projectImage">
          <el-image :src="projectForm.projectImage" alt="" class="el-image-big" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadProjectImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w710*h314;格式:png/jpg;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="projectForm.name"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="projectForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目状态描述" prop="projectState">
          <el-input v-model="projectForm.projectState"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="projectForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="label">
          <el-input v-model="projectForm.label"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="projectForm.price"></el-input>
        </el-form-item>
        <el-form-item label="面积" prop="area">
          <el-input v-model="projectForm.area"></el-input>
        </el-form-item>
        <el-form-item label="位置" prop="location">
          <el-input v-model="projectForm.location">
            <el-button slot="append" @click="window.open('https://lbs.qq.com/getPoint/')">获取经纬度</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="经度" prop="location">
          <el-input v-model="projectForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="location">
          <el-input v-model="projectForm.latitude"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="projectForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="image">
          <div class="el-image-list" v-for="item in projectForm.image.split(',')">
            <el-image :src="item" alt="" class="el-image-mid">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button type="text" size="mini" @click="deleteImage(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadImageSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:png/jpg;大小<=500k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频" prop="video">
          <div class="el-image-list" v-for="item in projectForm.video.split(',')">
            <video controls="controls" class="el-image-mid" :src="item"></video>
            <el-button type="text" size="mini" @click="deleteVideo(item)" icon="el-icon-delete" class="el-image-list-button"></el-button>
          </div>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeVideoUpload"
              :on-success="uploadVideoSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">上传</el-button><div style="color: red;">注：(尺寸:w750*h460;格式:mp4;大小<=20M)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" :rows="3" v-model="projectForm.description"></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <editor v-model="projectForm.detail"></editor>
        </el-form-item>
      </el-form>
	  <div style="color: red;">详情上传注：(图片尺寸:w750*不限高;格式:png/jpg;建议大小<=2M；视频尺寸:w750*不限高;格式:mp4;建议大小<=20M)</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitProjectForm('editProjectForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delProjectVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteProject">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 户型列表 -->
    <el-dialog title="户型列表" :visible.sync="showHouseTypeListVisible" width="640px">
      <el-table
          :data="houseTypeList"
          style="width: 100%;margin-bottom: 40px;min-height: 400px;">
        <el-table-column label="编号" prop="houseTypeId" min-width="150" align="center"></el-table-column>
        <el-table-column label="户型名称" prop="name" min-width="150" align="center"></el-table-column>
        <el-table-column label="户型价格" prop="price" min-width="150" align="center"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 顾问列表 -->
    <el-dialog title="顾问列表" :visible.sync="showAdviserListVisible" width="640px">
      <el-table
          :data="adviserList"
          style="width: 100%;margin-bottom: 40px;min-height: 400px;">
        <el-table-column label="编号" prop="adviserId" min-width="150" align="center"></el-table-column>
        <el-table-column label="顾问姓名" prop="name" min-width="150" align="center"></el-table-column>
        <el-table-column label="顾问电话" prop="phone" min-width="150" align="center"></el-table-column>
        <el-table-column label="顾问职位" prop="post" min-width="150" align="center"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 区位列表 -->
    <el-dialog title="区位列表" :visible.sync="showLocationListVisible" width="1000px">
      <el-table
          :data="locationList"
          style="width: 100%;margin-bottom: 40px;min-height: 400px;">
        <el-table-column label="编号" prop="locationId" min-width="150" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="位置1" min-width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.place1 }} | {{ scope.row.distance1 }}
          </template>
        </el-table-column>
        <el-table-column label="位置2" min-width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.place2 }} | {{ scope.row.distance2 }}
          </template>
        </el-table-column>
        <el-table-column label="位置3" min-width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.place3 }} | {{ scope.row.distance3 }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>

import Editor from "../components/Editor";

export default {
  name: 'project',
  components: {
    'editor': Editor
  },
  data () {
    return {
      config: '',
      projectForm: {
        projectId: '',
        name: '',
        description: '',
        detail: '',
        projectImage: '',
        projectState: '',
        contact: '',
        image: '',
        video: '',
        label: '',
        price: '',
        area: '',
        location: '',
        longitude: '',
        latitude: '',
        sort: 100,
        state: 1
      },
      projectList: [], // 记录项目信息，用于显示
      adviserList: [], // 顾问列表
      houseTypeList: [], // 户型列表
      locationList: [], // 区位列表
      searchForm: {
        state: 1,
        name: ''
      }, // 搜索
      addProjectVisible: false, // 显示添加框
      editProjectVisible: false, // 显示编辑框
      delProjectVisible: false, // 显示删除框
      showHouseTypeListVisible: false, // 显示户型列表框
      showAdviserListVisible: false, // 显示顾问列表框
      showLocationListVisible: false, // 显示区位列表框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      projectRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '名称长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        projectImage: [
          { required: true, message: '请选择项目主图', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '项目主图链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        contact: [
          { required: true, message: '请选择联系方式', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '联系方式链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        sort: [
          { required: true, message: '请输排序', trigger: 'blur' },
          { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getProjectList(this.searchForm)
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化项目表单数据
    initProjectForm() {
      this.projectForm = {
        projectId: '',
        name: '',
        description: '',
        detail: '',
        projectImage: '',
        projectState: '',
        contact: '',
        image: '',
        video: '',
        label: '',
        price: '',
        area: '',
        location: '',
        longitude: '',
        latitude: '',
        sort: 100,
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getProjectList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getProjectList(this.searchForm)
    },
	// 清空条件
	resetFilters() {
	      this.searchForm = {
	        state: '',
	        name: ''
	      }
		  },
    // 项目主图上传成功事件
    uploadProjectImageSuccess(res) {
      this.projectForm.projectImage = res.data
    },
    // 项目图片上传成功事件
    uploadImageSuccess(res) {
      if (this.projectForm.image == '') {
        this.projectForm.image = res.data
      } else {
        this.projectForm.image += ',' + res.data
      }
    },
    // 项目视频上传成功事件
    uploadVideoSuccess(res) {
      if (this.projectForm.video == '') {
        this.projectForm.video = res.data
      } else {
        this.projectForm.video += ',' + res.data
      }
    },
    // 删除图片
    async deleteImage (item) {
      const { data: res } = await this.$axios.delete('/file/delete', { params: { url: item } })
      if (res.code === 0) {
        this.projectForm.image = this.projectForm.image.replace(',' + item, '')
        this.projectForm.image = this.projectForm.image.replace(item, '')
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
    },
    // 删除视频
    async deleteVideo (item) {
      const { data: res } = await this.$axios.delete('/file/delete', { params: { url: item } })
      if (res.code === 0) {
        this.projectForm.video = this.projectForm.video.replace(',' + item, '')
        this.projectForm.video = this.projectForm.video.replace(item, '')
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
    },
    // 获取项目列表
    async getProjectList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/project/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.projectId = item.projectId
        arr.name = item.name
        arr.description = item.description
        arr.detail = item.detail
        arr.projectImage = item.projectImage
        arr.projectState = item.projectState
        arr.contact = item.contact
        arr.image = item.image
        arr.video = item.video
        arr.label = item.label
        arr.price = item.price
        arr.area = item.area
        arr.location = item.location
        arr.longitude = item.longitude
        arr.latitude = item.latitude
        arr.sort = item.sort
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.projectList = arrData
    },
    // 提交表单
    submitProjectForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addProjectForm') {
            this.addProject()
          } else if (formName === 'editProjectForm') {
            this.editProject()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加项目
    async addProject () {
      const { data: res } = await this.$axios.post('/project', this.projectForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addProjectVisible = false
      this.getProjectList(this.searchForm)
    },
	
    // 加载编辑项目
    loadEditProject (row) {
      this.projectForm = {
        projectId: row.projectId,
        name: row.name,
        description: row.description,
        detail: row.detail,
        projectImage: row.projectImage,
        projectState: row.projectState,
        contact: row.contact,
        image: row.image,
        video: row.video,
        label: row.label,
        price: row.price,
        area: row.area,
        location: row.location,
        longitude: row.longitude,
        latitude: row.latitude,
        sort: row.sort,
        state: row.state
      }
      this.editProjectVisible = true
    },
    // 编辑项目
    async editProject () {
      const { data: res } = await this.$axios.put('/project', this.projectForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editProjectVisible = false
      this.getProjectList(this.searchForm)
    },
    deleteProjectConfirm (projectId) {
      this.projectForm.projectId = projectId
      this.delProjectVisible = true
    },
    // 删除项目
    async deleteProject () {
      const { data: res } = await this.$axios.delete(`/project`, { params: { projectId: this.projectForm.projectId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delProjectVisible = false
      this.getProjectList(this.searchForm)
    },
    // 显示户型列表
    async showHouseTypeList(projectId) {
      this.showHouseTypeListVisible = true
      // 获取户型列表
      const {data: res} = await this.$axios.get(`/houseType/list`, { params: { projectId: projectId } })
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.houseTypeId = item.houseTypeId
        arr.name = item.name
        arr.price = item.price
        arrData.push(arr)
      })
      this.houseTypeList = arrData
    },
    // 显示顾问列表
    async showAdviserList(projectId) {
      this.showAdviserListVisible = true
      // 获取顾问列表
      const {data: res} = await this.$axios.get(`/adviser/list`, { params: { projectId: projectId } })
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.adviserId = item.adviserId
        arr.name = item.name
        arr.phone = item.phone
        arr.post = item.post
        arrData.push(arr)
      })
      this.adviserList = arrData
    },
    // 显示顾问列表
    async showLocationList(projectId) {
      this.showLocationListVisible = true
      // 获取顾问列表
      const {data: res} = await this.$axios.get(`/location/list`, { params: { projectId: projectId } })
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.locationId = item.locationId
        arr.name = item.name
        arr.place1 = item.place1
        arr.distance1 = item.distance1
        arr.place2 = item.place2
        arr.distance2 = item.distance2
        arr.place3 = item.place3
        arr.distance3 = item.distance3
        arrData.push(arr)
      })
      this.locationList = arrData
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('file', file)
      this.$axios({
        url:`/file/upload`,
        method: 'POST',
        data: formData
      }).then(res => {
        Editor.insertEmbed(cursorLocation, 'image', res.data.data)
        resetUploader()
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
</style>
