<template>
  <div>
    <g-header></g-header>
    <g-aside></g-aside>
    <div class="content-box" :class="{'content-collapse':collapse}">
      <div class="content">
        <!-- 标签栏 -->
        <div class="tabs">
          <el-tabs v-model="activeIndex" @tab-remove="removeTab">
            <!-- :closable 首页不可关闭 -->
            <el-tab-pane
                v-for="item in openTab"
                :key="item.title"
                :label="item.title"
                :name="item.index"
                :closable="item.title !== '首页' ">
              <!-- keep-alive 保持路由状态 -->
              <keep-alive>
                <router-view></router-view>
              </keep-alive>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Aside from './Aside.vue'
  import _ctrEvent from '../assets/js/ctr-event'

  export default {
    components: {
      'g-header': Header,
      'g-aside': Aside
    },
    data () {
      return {
        collapse: false // 当collapse为true时侧边栏折叠，并将页面内容部分向左扩展，以填充侧边栏折叠时产生的空白
      }
    },
    created () {
      // 监听collapse事件，将侧边栏折叠数据更新为子组件提交的msg
      _ctrEvent.$on('collapse', msg => {
        this.collapse = msg
      })
    },
    // 计算属性 从状态管理中获取的数据，因为需要获取和设置store中的数据，所以需要用 get 和 set
    computed: {
      // 已打开的标签页
      openTab: {
        get: function () {
          return this.$store.state.openTab;
        },
        // 用于删除标签页时将删除后的数据传回store
        set: function (newValue) {
          // console.log(newValue);
          this.$store.state.openTab = newValue;
        },
      },
      // 当前标签页
      activeIndex: {
        get: function () {
          return this.$store.state.activeIndex;
        },
        set: function (newValue) {
          // 当前标签页变化时触发
          // console.log(1111, newValue);
          this.$store.state.activeIndex = newValue;
          this.$router.push(newValue);
        },
      },
    },
    methods: {
      // 删除tab，直接在elementUI官网复制的，改了变量名
      removeTab(targetName) {
        let tabs = this.openTab;
        let activeIndex = this.activeIndex;
        if (activeIndex === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.index === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeIndex = nextTab.index;
              }
            }
          });
        }

        this.activeIndex = activeIndex;
        this.openTab = tabs.filter((tab) => tab.index !== targetName);
      },
    }
  }
</script>

<style>
</style>
