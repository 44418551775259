<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="searchForm.projectId" placeholder="请选择所属项目" class="handle-input mr10 mb10">
          <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.name"
              :value="item.projectId">
          </el-option>
        </el-select>
        <el-input v-model="searchForm.name" placeholder="请输入姓名" class="handle-input mr10 mb10"></el-input>
        <el-input v-model="searchForm.phone" placeholder="请输入电话" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
		<el-button type="default" @click="resetFilters">清空筛选条件</el-button>
        <el-button type="primary" @click="addAdviserVisible = true,initAdviserForm()">添加</el-button>
      </div>
      <el-table :data="adviserList" border style="width: 100%;min-height: 550px;" ref="adviserTable">
        <el-table-column label="编号" prop="adviserId" min-width="200" align="center"></el-table-column>
        <el-table-column label="头像" min-width="150" align="center">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatar" alt="" class="el-image" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" min-width="200" align="center"></el-table-column>
        <el-table-column label="性别" prop="gender" min-width="200" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.gender == 1">男</span>
            <span v-if="scope.row.gender == 2">女</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属项目" prop="projectName" min-width="200" align="center"></el-table-column>
        <el-table-column label="电话" prop="phone" min-width="200" align="center"></el-table-column>
        <el-table-column label="职位" prop="post" min-width="200" align="center"></el-table-column>
        <el-table-column label="排序" prop="sort" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditAdviser(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteAdviserConfirm(scope.row.adviserId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新顾问-->
    <el-dialog title="添加顾问" :visible.sync="addAdviserVisible" width="600px">
      <el-form :model="adviserForm" status-icon :rules="adviserRules" ref="addAdviserForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="图片" prop="avatar">
          <el-avatar :src="adviserForm.avatar" alt="" class="el-image-mid1" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w250*h250;格式:jpg/png;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="adviserForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="adviserForm.gender" placeholder="请选择性别">
            <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="adviserForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目" prop="projectId">
          <el-select v-model="adviserForm.projectId" placeholder="请选择所属项目">
            <el-option
                v-for="item in projectList"
                :key="item.projectId"
                :label="item.name"
                :value="item.projectId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="adviserForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="post">
          <el-input v-model="adviserForm.post"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="adviserForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAdviserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAdviserForm('addAdviserForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="顾问编辑" :visible.sync="editAdviserVisible" width="600px">
      <el-form :model="adviserForm" status-icon :rules="adviserRules" ref="editAdviserForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="图片" prop="avatar">
          <el-avatar :src="adviserForm.avatar" alt="" class="el-image-mid1" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button><div style="color: red;">注：(尺寸:w250*h250;格式:jpg/png;大小<=100k)</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="adviserForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="adviserForm.gender" placeholder="请选择性别">
            <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="adviserForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目" prop="projectId">
          <el-select v-model="adviserForm.projectId" placeholder="请选择所属项目">
            <el-option
                v-for="item in projectList"
                :key="item.projectId"
                :label="item.name"
                :value="item.projectId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="adviserForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="post">
          <el-input v-model="adviserForm.post"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="adviserForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAdviserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAdviserForm('editAdviserForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delAdviserVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delAdviserVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteAdviser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'adviser',
  data () {
    return {
      config: '',
      adviserForm: {
        adviserId: '',
        projectId: '',
        avatar: '',
        name: '',
        post: '',
        gender: 1,
        phone: '',
        remake: '',
        sort: 100,
        state: 1
      },
      adviserList: [], // 记录顾问信息，用于显示
      searchForm: {
        state: 1,
        projectId: '',
        name: '',
        phone: ''
      }, // 搜索
      addAdviserVisible: false, // 显示添加框
      editAdviserVisible: false, // 显示编辑框
      delAdviserVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      genderList: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 2,
          name: '女'
        }
      ],
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      projectList: [], // 项目列表
      adviserRules: {
        projectId: [
          { required: true, message: '请选择所属项目', trigger: 'blur' },
        ],
        avatar: [
          { required: true, message: '请输入头像链接', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '头像链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '姓名长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '电话长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        sort: [
          { required: true, message: '请输排序', trigger: 'blur' },
          { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getAdviserList(this.searchForm)
    this.getProjectList()
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化顾问表单数据
    initAdviserForm() {
      this.adviserForm = {
        adviserId: '',
        projectId: '',
        avatar: '',
        name: '',
        post: '',
        gender: 1,
        phone: '',
        remake: '',
        sort: 100,
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getAdviserList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getAdviserList(this.searchForm)
    },
	// 清空条件
	resetFilters() {
	      this.searchForm = {
	       state: '',
	       projectId: '',
	       name: '',
	       phone: ''
	      }
		  },
    // 顾问片上传成功事件
    uploadSuccess(res) {
      this.adviserForm.avatar = res.data
    },
    // 获取项目列表
    async getProjectList() {
      const {data: res} = await this.$axios.get(`/project/list`)
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.projectId = item.projectId
        arr.name = item.name
        arrData.push(arr)
      })
      this.projectList = arrData
    },
    // 获取顾问列表
    async getAdviserList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/adviser/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.adviserId = item.adviserId
        arr.projectId = item.projectId
        arr.projectName = item.projectName
        arr.avatar = item.avatar
        arr.name = item.name
        arr.post = item.post
        arr.gender = item.gender
        arr.phone = item.phone
        arr.remake = item.remake
        arr.sort = item.sort
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.adviserList = arrData
    },
    // 提交表单
    submitAdviserForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addAdviserForm') {
            this.addAdviser()
          } else if (formName === 'editAdviserForm') {
            this.editAdviser()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加顾问
    async addAdviser () {
      const { data: res } = await this.$axios.post('/adviser', this.adviserForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addAdviserVisible = false
      this.getAdviserList(this.searchForm)
    },
    // 加载编辑顾问
    loadEditAdviser (row) {
      this.adviserForm = {
        adviserId: row.adviserId,
        projectId: row.projectId,
        avatar: row.avatar,
        name: row.name,
        post: row.post,
        gender: row.gender,
        phone: row.phone,
        remake: row.remake,
        sort: row.sort,
        state: row.state
      }
      this.editAdviserVisible = true
    },
    // 编辑顾问
    async editAdviser () {
      const { data: res } = await this.$axios.put('/adviser', this.adviserForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editAdviserVisible = false
      this.getAdviserList(this.searchForm)
    },
    deleteAdviserConfirm (adviserId) {
      this.adviserForm.adviserId = adviserId
      this.delAdviserVisible = true
    },
    // 删除顾问
    async deleteAdviser () {
      const { data: res } = await this.$axios.delete(`/adviser`, { params: { adviserId: this.adviserForm.adviserId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delAdviserVisible = false
      this.getAdviserList(this.searchForm)
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
</style>
