<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="searchForm.projectId" placeholder="请选择所属项目" class="handle-input mr10 mb10">
          <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.name"
              :value="item.projectId">
          </el-option>
        </el-select>
        <el-date-picker v-model="searchForm.reseDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择预约日期" class="handle-input mr10 mb10"></el-date-picker>
        <el-input v-model="searchForm.reseName" placeholder="请输入预约姓名" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
		<el-button type="default" @click="resetFilters">清空筛选条件</el-button>
<!--        <el-button type="primary" @click="addReservationVisible = true,initReservationForm()">添加</el-button>-->
        <el-button type="primary" @click="exportReservationList">导出</el-button>
      </div>
      <el-table :data="reservationList" border style="width: 100%;min-height: 550px;" ref="reservationTable">
        <el-table-column label="编号" prop="reservationId" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约项目" prop="projectName" min-width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">已预约</el-tag>
            <el-tag type="info" v-if="scope.row.state == 2">预约过期</el-tag>
            <el-tag type="warning" v-if="scope.row.state == 3">取消预约</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="预约姓名" prop="reseName" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约电话" prop="resePhone" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约地址" prop="reseAddress" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约人数" prop="number" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约日期" prop="reseDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="预约时间段" prop="reseTime" min-width="200" align="center"></el-table-column>
        <el-table-column label="用户昵称" prop="userNickname" min-width="200" align="center"></el-table-column>
        <el-table-column label="用户姓名" prop="userName" min-width="200" align="center"></el-table-column>
        <el-table-column label="用户电话" prop="userPhone" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditReservation(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteReservationConfirm(scope.row.reservationId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

<!--    &lt;!&ndash;添加新预约&ndash;&gt;-->
<!--    <el-dialog title="添加预约" :visible.sync="addReservationVisible" width="600px">-->
<!--      <el-form :model="reservationForm" status-icon :rules="reservationRules" ref="addReservationForm" label-width="120px" class="demo-ruleForm">-->
<!--        <el-form-item label="预约姓名" prop="reseName">-->
<!--          <el-input v-model="reservationForm.reseName"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="预约电话" prop="resePhone">-->
<!--          <el-input v-model="reservationForm.resePhone"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="预约地址" prop="reseAddress">-->
<!--          <el-input v-model="reservationForm.reseAddress"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="预约项目" prop="projectId">-->
<!--          <el-select v-model="reservationForm.projectId" placeholder="请选择所属项目">-->
<!--            <el-option-->
<!--                v-for="item in projectList"-->
<!--                :key="item.projectId"-->
<!--                :label="item.name"-->
<!--                :value="item.projectId">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="预约日期" prop="reseDate">-->
<!--          <el-input :value="reservationForm.reseDate"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="预约时间段" prop="reseTime">-->
<!--          <el-input :value="reservationForm.reseTime"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="状态" prop="state">-->
<!--          <el-select v-model="reservationForm.state" placeholder="请选择状态">-->
<!--            <el-option-->
<!--                v-for="item in stateList"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="用户昵称" prop="userNickname">-->
<!--          <el-input :value="reservationForm.userNickname" :disabled="true"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="用户姓名" prop="userName">-->
<!--          <el-input :value="reservationForm.userName" :disabled="true"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="用户电话" prop="userPhone">-->
<!--          <el-input :value="reservationForm.userPhone" :disabled="true"></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="addReservationVisible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="submitReservationForm('addReservationForm')">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->

    <!-- 编辑弹出框 -->
    <el-dialog title="预约编辑" :visible.sync="editReservationVisible" width="600px">
      <el-form :model="reservationForm" status-icon :rules="reservationRules" ref="editReservationForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="预约姓名" prop="reseName">
          <el-input v-model="reservationForm.reseName"></el-input>
        </el-form-item>
        <el-form-item label="预约电话" prop="resePhone">
          <el-input v-model="reservationForm.resePhone"></el-input>
        </el-form-item>
        <el-form-item label="预约地址" prop="reseAddress">
          <el-input v-model="reservationForm.reseAddress"></el-input>
        </el-form-item>
        <el-form-item label="预约项目" prop="projectId">
          <el-select v-model="reservationForm.projectId" placeholder="请选择所属项目">
            <el-option
                v-for="item in projectList"
                :key="item.projectId"
                :label="item.name"
                :value="item.projectId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约日期" prop="reseDate">
          <el-input :value="reservationForm.reseDate" placeholder="请输入日期，格式为：2024-06-01"></el-input>
        </el-form-item>
        <el-form-item label="预约时间段" prop="reseTime">
          <el-input :value="reservationForm.reseTime" placeholder="请输入时间段，格式为：08:00-08:30"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="reservationForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户昵称" prop="userNickname">
          <el-input :value="reservationForm.userNickname" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名" prop="userName">
          <el-input :value="reservationForm.userName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="用户电话" prop="userPhone">
          <el-input :value="reservationForm.userPhone" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editReservationVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReservationForm('editReservationForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delReservationVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delReservationVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteReservation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'reservation',
  data () {
    return {
      reservationForm: {
        reservationId: '',
        projectId: '',
        userId: '',
        userNickname: '',
        userName: '',
        userPhone: '',
        reseName: '',
        resePhone: '',
        reseAddress: '',
        reseDate: '',
        reseTime: '',
        number: '',
        state: 1
      },
      reservationList: [], // 记录预约信息，用于显示
      searchForm: {
        state: 1,
        projectId: '',
        reseDate: '',
        reseName: ''
      }, // 搜索
      addReservationVisible: false, // 显示添加框
      editReservationVisible: false, // 显示编辑框
      delReservationVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      stateList: [
        {
          id: 1,
          name: '已预约'
        },
        {
          id: 2,
          name: '预约过期'
        },
        {
          id: 3,
          name: '取消预约'
        }
      ],
      projectList: [], // 项目列表
      reservationRules: {
        projectId: [
          { required: true, message: '请选择项目', trigger: 'blur' },
        ],
        avatar: [
          { required: true, message: '请输入头像链接', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '头像链接长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '姓名长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入号码', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '号码长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        sort: [
          { required: true, message: '请输排序', trigger: 'blur' },
          { validator: this.checkInt, message: '排序为整数', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getReservationList(this.searchForm)
    this.getProjectList()
  },
  methods: {
    // 初始化预约表单数据
    initReservationForm() {
      this.reservationForm = {
        reservationId: '',
        projectId: '',
        userId: '',
        userNickname: '',
        userName: '',
        userPhone: '',
        reseName: '',
        resePhone: '',
        reseAddress: '',
        reseDate: '',
        reseTime: '',
        number: '',
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getReservationList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getReservationList(this.searchForm)
    },
	// 清空条件
	resetFilters() {
	      this.searchForm = {
	      state: '',
	      projectId: '',
	      reseDate: '',
	      reseName: ''
	      }
		  },
    // 获取项目列表
    async getProjectList() {
      const {data: res} = await this.$axios.get(`/project/list`)
      const arrData = []
      res.data.forEach(item => {
        const arr = {}
        arr.projectId = item.projectId
        arr.name = item.name
        arrData.push(arr)
      })
      this.projectList = arrData
    },
    // 获取预约列表
    async getReservationList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/reservation/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.reservationId = item.reservationId
        arr.projectId = item.projectId
        arr.projectName = item.projectName
        arr.userId = item.userId
        arr.userNickname = item.userNickname
        arr.userName = item.userName
        arr.userPhone = item.userPhone
        arr.reseName = item.reseName
        arr.resePhone = item.resePhone
        arr.reseAddress = item.reseAddress
        arr.reseDate = item.reseDate
        arr.reseTime = item.reseTime
        arr.number = item.number
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.reservationList = arrData
    },
    // 导出用户列表
    async exportReservationList () {
      await this.exportExcel('/reservation/export', this.searchForm)
    },
    // 提交表单
    submitReservationForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addReservationForm') {
            this.addReservation()
          } else if (formName === 'editReservationForm') {
            this.editReservation()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加预约
    async addReservation () {
      const { data: res } = await this.$axios.post('/reservation', this.reservationForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addReservationVisible = false
      this.getReservationList(this.searchForm)
    },
    // 加载编辑预约
    loadEditReservation (row) {
      this.reservationForm = {
        reservationId: row.reservationId,
        projectId: row.projectId,
        userId: row.userId,
        userNickname: row.userNickname,
        userName: row.userName,
        userPhone: row.userPhone,
        reseName: row.reseName,
        resePhone: row.resePhone,
        reseAddress: row.reseAddress,
        reseDate: row.reseDate,
        reseTime: row.reseTime,
        number: row.number,
        state: row.state
      }
      this.editReservationVisible = true
    },
    // 编辑预约
    async editReservation () {
      const { data: res } = await this.$axios.put('/reservation', this.reservationForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editReservationVisible = false
      this.getReservationList(this.searchForm)
    },
    deleteReservationConfirm (reservationId) {
      this.reservationForm.reservationId = reservationId
      this.delReservationVisible = true
    },
    // 删除预约
    async deleteReservation () {
      const { data: res } = await this.$axios.delete(`/reservation`, { params: { reservationId: this.reservationForm.reservationId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delReservationVisible = false
      this.getReservationList(this.searchForm)
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
el-table-column > el-button {
  margin-top 3px;
  margin-bottom: 3px;
}
</style>
