<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-select v-model="searchForm.state" placeholder="请选择状态" class="handle-input mr10 mb10">
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input v-model="searchForm.username" placeholder="请输入用户名" class="handle-input mr10 mb10"></el-input>
        <el-input v-model="searchForm.phone" placeholder="请输入电话" class="handle-input mr10 mb10"></el-input>
        <el-button type="primary" @click="searchEvent">搜索</el-button>
        <el-button type="primary" @click="addAdminVisible = true,initAdminForm()">添加</el-button>
      </div>
      <el-table :data="adminList" border style="width: 100%;min-height: 550px;" ref="adminTable">
        <el-table-column label="编号" prop="adminId" min-width="200" align="center"></el-table-column>
        <el-table-column label="头像" min-width="150" align="center">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatar" alt="" class="el-image" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="用户名" prop="username" min-width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" min-width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">生效中</el-tag>
            <el-tag type="info" v-if="scope.row.state == 0">未生效</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone" min-width="200" align="center"></el-table-column>
        <el-table-column label="部门" prop="dept" min-width="200" align="center"></el-table-column>
        <el-table-column label="职位" prop="post" min-width="200" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="更新时间" prop="updateDate" min-width="200" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" class="table-el-button" @click="loadEditAdmin(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" class="table-el-button" @click="deleteAdminConfirm(scope.row.adminId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="changePage"
            background
            layout="total, prev, pager, next"
            :current-page="page.page"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>

    <!--添加新管理员-->
    <el-dialog title="添加管理员" :visible.sync="addAdminVisible" width="600px">
      <el-form :model="adminForm" status-icon :rules="adminRules" ref="addAdminForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="头像" prop="avatar">
          <el-avatar :src="adminForm.avatar" alt="" class="el-image-mid" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="adminForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="adminForm.password"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="adminForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="name">
          <el-input v-model="adminForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="phone">
          <el-input v-model="adminForm.dept"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="post">
          <el-input v-model="adminForm.post"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAdminVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAdminForm('addAdminForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="管理员编辑" :visible.sync="editAdminVisible" width="600px">
      <el-form :model="adminForm" status-icon :rules="adminRules" ref="editAdminForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="头像" prop="avatar">
          <el-avatar :src="adminForm.avatar" alt="" class="el-image-mid" >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-avatar>
          <el-upload
              class="upload-demo"
              :action="$baseURL + '/file/upload'"
              :headers="config"
              :before-upload="beforeImageUpload"
              :on-success="uploadSuccess"
              :show-file-list="false">
            <el-button slot="trigger" size="mini">更换</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="adminForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="adminForm.password"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="adminForm.state" placeholder="请选择状态">
            <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" prop="name">
          <el-input v-model="adminForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="phone">
          <el-input v-model="adminForm.dept"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="post">
          <el-input v-model="adminForm.post"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAdminVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAdminForm('editAdminForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delAdminVisible" width="300px">
      <div class="del-dialog-cnt" align="center">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delAdminVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteAdmin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'admin',
  data () {
    return {
      config: '',
      adminForm: {
        adminId: '',
        username: '',
        password: '',
        avatar: '',
        phone: '',
        dept: '',
        post: '',
        state: 1
      },
      adminList: [], // 记录管理员信息，用于显示
      searchForm: {
        state: 1,
        username: '',
        phone: ''
      }, // 搜索
      addAdminVisible: false, // 显示添加框
      editAdminVisible: false, // 显示编辑框
      delAdminVisible: false, // 显示删除框
      page: {
        page: 1,
        size: 10,
        total: 0
      },
      stateList: [
        {
          id: 1,
          name: '生效中'
        },
        {
          id: 0,
          name: '未生效'
        }
      ],
      adminRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 2,
            max: 255,
            message: '用户名长度在 2 到 255 个字符',
            trigger: 'blur'
          }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  created () {
    this.getAdminList(this.searchForm)
    this.config = {
      'Authorization': this.$store.state.token
    }
  },
  methods: {
    // 初始化管理员表单数据
    initAdminForm() {
      this.adminForm = {
        adminId: '',
        username: '',
        password: '',
        avatar: '',
        phone: '',
        dept: '',
        post: '',
        state: 1
      }
    },
    // 切换页
    changePage (page) {
      this.page.page = page
      this.getAdminList(this.searchForm)
    },
    // 搜索事件
    searchEvent() {
      this.page.page = 1
      this.getAdminList(this.searchForm)
    },
    // 管理员片上传成功事件
    uploadSuccess(res) {
      this.adminForm.avatar = res.data
    },
    // 获取管理员列表
    async getAdminList (params) {
      Object.assign(params, params, this.page)
      const { data: res } = await this.$axios.get(`/admin/page`, { params: params})
      const arrData = []
      res.data.records.forEach(item => {
        const arr = {}
        arr.adminId = item.adminId
        arr.username = item.username
        arr.avatar = item.avatar
        arr.phone = item.phone
        arr.dept = item.dept
        arr.post = item.post
        arr.state = item.state
        arr.createDate = item.createDate
        arr.updateDate = item.updateDate
        arrData.push(arr)
      })
      this.page.total = res.data.total
      this.adminList = arrData
    },
    // 提交表单
    submitAdminForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'addAdminForm') {
            this.addAdmin()
          } else if (formName === 'editAdminForm') {
            this.editAdmin()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 添加管理员
    async addAdmin () {
      const { data: res } = await this.$axios.post('/admin', this.adminForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.addAdminVisible = false
      this.getAdminList(this.searchForm)
    },
    // 加载编辑管理员
    loadEditAdmin (row) {
      this.adminForm = {
        adminId: row.adminId,
        username: row.username,
        avatar: row.avatar,
        phone: row.phone,
        dept: row.dept,
        post: row.post,
        state: row.state
      }
      this.editAdminVisible = true
    },
    // 编辑管理员
    async editAdmin () {
      const { data: res } = await this.$axios.put('/admin', this.adminForm)
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.editAdminVisible = false
      this.getAdminList(this.searchForm)
    },
    deleteAdminConfirm (adminId) {
      this.adminForm.adminId = adminId
      this.delAdminVisible = true
    },
    // 删除管理员
    async deleteAdmin () {
      const { data: res } = await this.$axios.delete(`/admin`, { params: { adminId: this.adminForm.adminId }})
      if (res.code === 0) {
        this.$notify({title: res.msg, type: 'success'})
      } else {
        this.$notify({title: res.msg, type: 'error'})
      }
      this.delAdminVisible = false
      this.getAdminList(this.searchForm)
    }
  }
}

</script>

<style lang="stylus" scoped>
.handle-box
  font-size 12px
.handle-input
  width 200px
  display inline-block
  margin-right 10px
.pagination
  display flex
  justify-content center
</style>
